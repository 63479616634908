'use client'

import Error from 'next/error'
import * as Sentry from '@sentry/nextjs'
import IconWarning from '@/public/assets/warning.svg'
import IconCheck from '@haiper/icons-svg/icons/outline/check-large.svg'
import { cls, copyText } from '@/utils'
import { NO_OUTLINE_STYLE } from '@/constants'
import Button from '@/components/button'
import { useCallback, useEffect, useRef, useState } from 'react'
import useInterval from 'use-interval'

export default function ErrorBoundary({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  const [copied, setCopied] = useState(false)
  const clickTimesRef = useRef(0)

  useEffect(() => {
    if (error) {
      Sentry.captureException(error)
    }
  }, [error])

  useInterval(() => {
    clickTimesRef.current = Math.max(0, clickTimesRef.current - 1)
  }, 500)

  // copy error to clipboard when clicked 5 times in 3 seconds
  const handleIconClick = useCallback(() => {
    clickTimesRef.current += 1
    if (clickTimesRef.current === 5) {
      copyText(String(error))
      setCopied(true)
      setTimeout(() => {
        clickTimesRef.current = 0
        setCopied(false)
      }, 1500)
    }
  }, [error])

  const StautsIcon = copied ? IconCheck : IconWarning
  const statusIconClassName = copied
    ? 'text-icon-success'
    : 'text-icon-critical'

  return (
    <article className='relative flex flex-col size-full items-center justify-center gap-6 flex-1'>
      <StautsIcon
        className={cls('size-[75px]', statusIconClassName)}
        onClick={handleIconClick}
      />
      <div className='select-none text-heading-2xl font-bold'>
        Something went wrong!
      </div>
      <Button
        variant='outline'
        className={cls('w-[135px] h-10 rounded-full', NO_OUTLINE_STYLE)}
        onClick={() => window?.location.reload()}
      >
        Refresh
      </Button>
    </article>
  )
}
